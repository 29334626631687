@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
  padding-inline-start: 0;
  font-family: Montserrat;
}

.blur {
  filter: blur(3px);
}